// Colors
$secondaryColor: #1f1f1e;
$primaryColor: lighten(
    $color: $secondaryColor,
    $amount: 10%,
);
$textColor: white;
$active: lighten(blue, 25%);
$modalBg: #333;

// Fonts
$fontStack: "Oswald", sans-serif;

// Screen sizes
$sm: 640px;
$md: 768px;
$lg: 1024px;
$xl: 1280px;
$xxl: 1536px;

// Gap from navbar
$navSize: 5.5rem;

html {
    color: $textColor;
    height: 100%;
    width: 100%;

    font-style: $fontStack;
}

nav {
    background-color: $secondaryColor;
    position: fixed;
    width: 100vw;

    top: 0;
    z-index: 1;

    display: flex;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;

    justify-content: space-between;
    align-items: center;

    #largeNavLocationList {
        list-style: none;
        display: none;
        margin-right: 1rem;

        li {
            margin-left: 1rem;

            a {
                background-color: $primaryColor;
                border: none;

                border-radius: 5px;
                color: $textColor;

                padding: 0.5rem 2rem 0.5rem 2rem;
                text-decoration: none;
            }

            a.activePage {
                background-color: $active;
            }
        }
    }

    font-family: $fontStack;

    .listicon {
        height: 2rem;
        width: 2rem;
        margin-right: 15px;
        margin-top: 15px;
        margin-bottom: 15px;
    }

    @media (min-width: $lg) {
        .listicon {
            display: none;
        }
        #largeNavLocationList {
            display: flex;
        }
    }

    div {
        display: flex;
        flex-direction: row;
    }

    font-size: 1rem;

    #logo {
        width: 4rem;

        margin-left: 1rem;
        padding-right: 1rem;
    }

    #mobileListView {
        position: absolute;
        background-color: $secondaryColor;
        width: 70%;
        height: 100vh;

        top: $navSize;
        left: 0;

        ul#verticalLinkList {
            list-style: none;
            display: flex;
            flex-direction: column;

            #socialsSeperator {
                width: 100%;
                border-bottom: $secondaryColor;
            }

            li.socialLink {
                height: 2rem;
                margin-top: 0.5rem;
                margin-bottom: 0.5rem;

                display: flex;
                flex-direction: row;

                align-items: center;

                a {
                    height: 2rem;
                    text-decoration: none;
                    color: $textColor;

                    display: flex;
                    align-items: center;

                    svg {
                        height: 2rem;
                        margin-right: 1rem;
                        color: $textColor;
                    }
                }
            }
        }
    }
}

footer {
    background-color: $secondaryColor;
    font-family: $fontStack;
    padding-top: 1px;
}

body {
    height: 100%;
    width: 100%;
    background-color: $secondaryColor;

    main {
        background-color: $primaryColor;
    }
}

#socials {
    padding-left: 1.5rem;
    margin-top: 10px;

    ul#SOCLIST {
        list-style: none;
        display: flex;
        flex-direction: column;

        padding-left: 1em;

        #socialsSeperator {
            width: 100%;
            border-bottom: $secondaryColor;
        }

        li.socialLink {
            height: 2rem;
            margin-top: 0.5rem;
            margin-bottom: 0.5rem;

            display: flex;
            flex-direction: row;

            align-items: center;

            a {
                height: 2rem;
                text-decoration: none;
                color: $textColor;

                display: flex;
                align-items: center;

                svg {
                    height: 2rem;
                    margin-right: 1rem;
                    color: $textColor;
                }
            }
        }
    }
}

.productContainer {
    list-style: none;
    display: flex;

    flex-wrap: wrap;

    margin-top: $navSize;
    padding: 0;
    padding-top: 10px;
    justify-content: center;

    li {
        padding: 10px;
        border-radius: 5px;
        background-color: $secondaryColor;

        margin: 1rem;

        max-width: 90%;

        @media (min-width: $md) {
            max-width: 50%;
        }

        @media (min-width: $lg) {
            max-width: 30%;
        }

        height: auto;
        display: flex;
        flex-direction: column;

        padding-bottom: 0;

        img {
            border-radius: 5px;
        }

        div {
            display: flex;
            flex-direction: row;

            button {
                margin: 10px;
                color: $textColor;
                font-family: $fontStack;
                background-color: $active;
                border-radius: 10px;

                width: 50%;
                border: none;

                &:hover {
                    background-color: darken($color: $active, $amount: 10%);
                }
            }
        }
        .propContainer {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding-left: 1rem;
            padding-right: 1rem;

            font-family: $fontStack;
        }
    }
}

#home {
    font-family: $fontStack;
    color: $textColor;

    margin-top: $navSize;
    padding: 1rem;

    text-align: center;
}

header.modalHeader {
    display: flex;
    flex-direction: col;

    justify-content: space-between;
    align-items: center;

    span.productTitle {
        font-family: $fontStack;
        font-size: 2rem;
    }
}

main.modalMain {
    overflow: hidden;
    color: $textColor;
    background-color: $modalBg;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 1rem;

    text-align: center;
    font-family: $fontStack;

    .description {
        padding: 1em;
        border-radius: 1em;
        background-color: $secondaryColor;

        font-family: sans-serif;
    }

    .priceNbuy {
        display: flex;
        flex-direction: row;

        justify-content: space-between;
        align-items: center;

        button {
            background-color: $active;
            color: $textColor;
            border: none;

            border-radius: 10px;
            padding: 1em 5em 1em 5em;

            height: 100%;
            font-family: $fontStack;

            &:hover {
                background-color: darken($color: $active, $amount: 10%);
            }
        }
    }
}

.slick-list {
    margin-top: 1em;
}

.slick-slider {
    width: 100%;
    justify-content: center;
}

.carouselImage {
    border-radius: 10px;
    height: 90%;
    width: 90%;

    max-width: 90%;

    @media (min-width: $md) {
        max-width: 75%;
    }

    @media (min-width: $lg) {
        max-width: 50%;
    }
}

.swiper-slide.swiper-slide-active,
.swiper-slide.swiper-slide-next,
.swiper-slide.swiper-slide-prev {
    display: flex;
    justify-content: center;
    align-items: center;
}

.slick-slider.slick-initialized {
    width: 90%;
}

.swiper.swiper-initialized {
    background-color: $modalBg;
    width: 100%;

    margin-bottom: 1em;
}

a {
    color: $textColor;
}

#sidebarLinks {
    display: flex;
    flex-direction: column;

    li {
        margin: 0.75em 0 0.75em 0;

        a {
            background-color: $primaryColor;
            border: none;

            border-radius: 5px;
            color: $textColor;

            padding: 0.5rem 2rem 0.5rem 2rem;
            text-decoration: none;
        }

        a.activePage {
            background-color: $active;
        }
    }
}

.leckoImage {
    height: 30em;
    border-radius: 10px;

    position: static;
}

#home {
    .swiper.swiper-initialized {
        background-color: $primaryColor;
        position: static;
        z-index: -1;

        .swiper-wrapper {
            position: static;

            .swiper-slide {
                position: static;
            }
        }

        margin-bottom: 0;
    }

    #GoToStore {
        background-color: $active;
        color: $textColor;

        border: none;
        padding: 0.5em 2em 0.5em 2em;

        border-radius: 10px;

        &:hover {
            background-color: darken($color: $active, $amount: 10%);
        }
    }
}

.swiper-button-prev.swiper-button-next {
    position: static;
    z-index: -1;
}

#p2 {
    margin-top: 2em;
}

footer {
    p {
        padding-left: 1.5rem;
        padding-bottom: 1.5rem;
    }
}

.frontImage {
    width: 100%;
    aspect-ratio: 1 / 1;
}

#submit {
    margin-top: 1em;

    padding: 0.5em 1em 0.5em 1em;
    border: none;
    border-radius: 10px;

    color: $textColor;
    background-color: $active;

    &:hover {
        background-color: darken($color: $active, $amount: 10%);
    }
}

.List {
    list-style: none;
    display: flex;
    flex-direction: row;

    justify-content: center;
    padding-left: 0;

    flex-wrap: wrap;

    margin-top: 0;

    li {
        background-color: $secondaryColor;
        padding: 0 1em 0 1em;
        border-radius: 10px;
        border: 2px solid gray;

        margin: 0.5em;
    }

    li.active {
        border-color: $active;
    }
}

.colorList {
    list-style: none;
    display: flex;
    flex-direction: row;

    padding-left: 0;
    flex-wrap: wrap;

    li {
        aspect-ratio: 1 / 1;

        border: 2px solid gray;
        margin-right: 1em;
        padding: 0;

        border-radius: 50%;
        width: 1em;
        height: 1em;
    }
}

label {
    text-align: left;
}

.description {
    border: 2px solid $active;
}

.productInfoBeforeBuy {
    display: flex;
    flex-wrap: wrap;

    justify-content: center;

    margin-top: 1em;
    margin-bottom: 1em;

    .heroImage {
        border-radius: 10px;

        width: 20em;

        border: 2px solid $active;
    }

    ul {
        background-color: $secondaryColor;
        border-radius: 10px;
        width: 30em;

        margin: 0;
        padding: 1em;

        list-style: none;

        border: 2px solid $active;
        display: flex;
        flex-direction: column;

        tr {
            width: 100%;
            height: 2em;
            padding-left: 4px;
        }
    }
}

.react-international-phone-input-container {
    justify-content: center;
}

.nextStep {
    background-color: $active;
    border: none;

    color: $textColor;
    border-radius: 10px;

    padding: 1em 1em 1em 1em;

    &:hover {
        background-color: darken($color: $active, $amount: 10%);
    }
}

form#contactInfo {
    display: flex;
    flex-direction: column;

    justify-content: center;

    #emailContainer {
        width: 100%;
        display: flex;
        justify-content: center;

        input#email {
            width: 37%;
            height: 2.5em;

            border: none;
            border-radius: 3px;
        }
    }

    #submitContainer {
        width: 100%;
        display: flex;
        justify-content: center;

        button.nextStep {
            width: 37%;
        }
    }

    .inputContainer,
    .react-international-phone-input-container {
        margin-bottom: 1em;
    }
}

#email {
    background-color: $primaryColor;
    border: 1px solid gray;
    padding-left: 1em;
}

.variantTitle {
    text-align: center;
}

#buy {
    background-color: $active;
    border: none;

    margin-bottom: 1em;
    color: $textColor;
    padding: 1em;

    border-radius: 1em;

    &:hover {
        background-color: darken($color: $active, $amount: 10);
    }
}

#OPTS {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-left: 0;
}

main#purchase_status {
    margin-top: $navSize;
    padding: 2em;
}