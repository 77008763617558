main#root {
  background: linear-gradient(to right, #1e5799 0%, #2ce0bf 19%, #76dd2c 40%, #dba62b 60%, #e02cbf 83%, #1e5799 100%);
  background-size: 10000px 100%;
  animation: bg 15s linear infinite;
}

@font-face {
  font-family: 'Questrian';
  src: url('fonts/Questrian.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

h1#title_text {
  font-family: 'Questrian', sans-serif;
  text-align: center; 
  display: flex;
  align-items: center;
  letter-spacing: 3px;
}

#greeting {
  font-family: 'Questrian', sans-serif;
  letter-spacing: 3px;
}

@keyframes bg {
  0% {
    background-position-x: 0;
  }
  100% {
    background-position-x: 10000px;
  }
}
html {
  color: white;
  height: 100%;
  width: 100%;
  font-style: "Oswald", sans-serif; }

nav {
  background-color: #1f1f1e;
  position: fixed;
  width: 100vw;
  top: 0;
  z-index: 1;
  display: flex;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  justify-content: space-between;
  align-items: center;
  font-family: "Oswald", sans-serif;
  font-size: 1rem; }
  nav #largeNavLocationList {
    list-style: none;
    display: none;
    margin-right: 1rem; }
    nav #largeNavLocationList li {
      margin-left: 1rem; }
      nav #largeNavLocationList li a {
        background-color: #393937;
        border: none;
        border-radius: 5px;
        color: white;
        padding: 0.5rem 2rem 0.5rem 2rem;
        text-decoration: none; }
      nav #largeNavLocationList li a.activePage {
        background-color: #8080ff; }
  nav .listicon {
    height: 2rem;
    width: 2rem;
    margin-right: 15px;
    margin-top: 15px;
    margin-bottom: 15px; }
  @media (min-width: 1024px) {
    nav .listicon {
      display: none; }
    nav #largeNavLocationList {
      display: flex; } }
  nav a {
    display: flex;
    flex-direction: row;
    text-decoration: none; }
  nav #logo {
    width: 4rem;
    margin-left: 1rem;
    padding-right: 1rem; }
  nav #mobileListView {
    position: absolute;
    background-color: #1f1f1e;
    width: 70%;
    height: 100vh;
    top: 5.5rem;
    left: 0; }
    nav #mobileListView ul#verticalLinkList {
      list-style: none;
      display: flex;
      flex-direction: column; }
      nav #mobileListView ul#verticalLinkList #socialsSeperator {
        width: 100%;
        border-bottom: #1f1f1e; }
      nav #mobileListView ul#verticalLinkList li.socialLink {
        height: 2rem;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
        display: flex;
        flex-direction: row;
        align-items: center; }
        nav #mobileListView ul#verticalLinkList li.socialLink a {
          height: 2rem;
          text-decoration: none;
          color: white;
          display: flex;
          align-items: center; }
          nav #mobileListView ul#verticalLinkList li.socialLink a svg {
            height: 2rem;
            margin-right: 1rem;
            color: white; }

footer {
  background-color: #1f1f1e;
  font-family: "Oswald", sans-serif;
  padding-top: 1px; }

body {
  height: 100%;
  width: 100%;
  background-color: #1f1f1e; }
  body main {
    background-color: #393937; }

#socials {
  padding-left: 1.5rem;
  margin-top: 10px; }
  #socials ul#SOCLIST {
    list-style: none;
    display: flex;
    flex-direction: column;
    padding-left: 1em; }
    #socials ul#SOCLIST #socialsSeperator {
      width: 100%;
      border-bottom: #1f1f1e; }
    #socials ul#SOCLIST li.socialLink {
      height: 2rem;
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
      display: flex;
      flex-direction: row;
      align-items: center; }
      #socials ul#SOCLIST li.socialLink a {
        height: 2rem;
        text-decoration: none;
        color: white;
        display: flex;
        align-items: center; }
        #socials ul#SOCLIST li.socialLink a svg {
          height: 2rem;
          margin-right: 1rem;
          color: white; }

.productContainer {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  margin-top: 5.5rem;
  padding: 0;
  padding-top: 10px;
  justify-content: center; }
  .productContainer li {
    padding: 10px;
    border-radius: 5px;
    background-color: #1f1f1e;
    margin: 1rem;
    max-width: 90%;
    height: auto;
    display: flex;
    flex-direction: column;
    padding-bottom: 0; }
    @media (min-width: 768px) {
      .productContainer li {
        max-width: 50%; } }
    @media (min-width: 1024px) {
      .productContainer li {
        max-width: 30%; } }
    .productContainer li img {
      border-radius: 5px; }
    .productContainer li div {
      display: flex;
      flex-direction: row; }
      .productContainer li div button {
        margin: 10px;
        color: white;
        font-family: "Oswald", sans-serif;
        background-color: #8080ff;
        border-radius: 10px;
        width: 50%;
        border: none; }
        .productContainer li div button:hover {
          background-color: #4d4dff; }
    .productContainer li .propContainer {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding-left: 1rem;
      padding-right: 1rem;
      font-family: "Oswald", sans-serif; }

#home {
  font-family: "Oswald", sans-serif;
  color: white;
  margin-top: 5.5rem;
  padding: 1rem;
  text-align: center; }

header.modalHeader {
  display: flex;
  flex-direction: col;
  justify-content: space-between;
  align-items: center; }
  header.modalHeader span.productTitle {
    font-family: "Oswald", sans-serif;
    font-size: 2rem; }

main.modalMain {
  overflow: hidden;
  color: white;
  background-color: #333;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 1rem;
  text-align: center;
  font-family: "Oswald", sans-serif; }
  main.modalMain .description {
    padding: 1em;
    border-radius: 1em;
    background-color: #1f1f1e;
    font-family: sans-serif; }
  main.modalMain .priceNbuy {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center; }
    main.modalMain .priceNbuy button {
      background-color: #8080ff;
      color: white;
      border: none;
      border-radius: 10px;
      padding: 1em 5em 1em 5em;
      height: 100%;
      font-family: "Oswald", sans-serif; }
      main.modalMain .priceNbuy button:hover {
        background-color: #4d4dff; }

.slick-list {
  margin-top: 1em; }

.slick-slider {
  width: 100%;
  justify-content: center; }

.carouselImage {
  border-radius: 10px;
  height: 90%;
  width: 90%;
  max-width: 90%; }
  @media (min-width: 768px) {
    .carouselImage {
      max-width: 75%; } }
  @media (min-width: 1024px) {
    .carouselImage {
      max-width: 50%; } }

.swiper-slide.swiper-slide-active,
.swiper-slide.swiper-slide-next,
.swiper-slide.swiper-slide-prev {
  display: flex;
  justify-content: center;
  align-items: center; }

.slick-slider.slick-initialized {
  width: 90%; }

.swiper.swiper-initialized {
  width: 100%;
  margin-bottom: 1em; }

a {
  color: white; }

#sidebarLinks {
  display: flex;
  flex-direction: column; }
  #sidebarLinks li {
    margin: 0.75em 0 0.75em 0; }
    #sidebarLinks li a {
      background-color: #393937;
      border: none;
      border-radius: 5px;
      color: white;
      padding: 0.5rem 2rem 0.5rem 2rem;
      text-decoration: none; }
    #sidebarLinks li a.activePage {
      background-color: #8080ff; }

.leckoImage {
  height: 30em;
  border-radius: 10px;
  position: static; }

#home .swiper.swiper-initialized {
  position: static;
  z-index: -1;
  margin-bottom: 0; }
  #home .swiper.swiper-initialized .swiper-wrapper {
    position: static; }
    #home .swiper.swiper-initialized .swiper-wrapper .swiper-slide {
      position: static; }
#home #GoToStore {
  background-color: #8080ff;
  color: white;
  border: none;
  padding: 0.5em 2em 0.5em 2em;
  border-radius: 10px; }
  #home #GoToStore:hover {
    background-color: #4d4dff; }

.swiper-button-prev.swiper-button-next {
  position: static;
  z-index: -1; }

#p2 {
  margin-top: 2em; }

footer p {
  padding-left: 1.5rem;
  padding-bottom: 1.5rem; }

.frontImage {
  width: 100%;
  aspect-ratio: 1 / 1; }

#submit {
  margin-top: 1em;
  padding: 0.5em 1em 0.5em 1em;
  border: none;
  border-radius: 10px;
  color: white;
  background-color: #8080ff; }
  #submit:hover {
    background-color: #4d4dff; }

.List {
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-left: 0;
  flex-wrap: wrap;
  margin-top: 0; }
  .List li {
    background-color: #1f1f1e;
    padding: 0 1em 0 1em;
    border-radius: 10px;
    border: 2px solid gray;
    margin: 0.5em; }
  .List li.active {
    border-color: #8080ff; }

.colorList {
  list-style: none;
  display: flex;
  flex-direction: row;
  padding-left: 0;
  flex-wrap: wrap; }
  .colorList li {
    aspect-ratio: 1 / 1;
    border: 2px solid gray;
    margin-right: 1em;
    padding: 0;
    border-radius: 50%;
    width: 1em;
    height: 1em; }

label {
  text-align: left; }

.description {
  border: 2px solid #8080ff; }

.productInfoBeforeBuy {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 1em;
  margin-bottom: 1em; }
  .productInfoBeforeBuy .heroImage {
    border-radius: 10px;
    width: 20em;
    border: 2px solid #8080ff; }
  .productInfoBeforeBuy ul {
    background-color: #1f1f1e;
    border-radius: 10px;
    width: 30em;
    margin: 0;
    padding: 1em;
    list-style: none;
    border: 2px solid #8080ff;
    display: flex;
    flex-direction: column; }
    .productInfoBeforeBuy ul tr {
      width: 100%;
      height: 2em;
      padding-left: 4px; }

.react-international-phone-input-container {
  justify-content: center; }

.nextStep {
  background-color: #8080ff;
  border: none;
  color: white;
  border-radius: 10px;
  padding: 1em 1em 1em 1em; }
  .nextStep:hover {
    background-color: #4d4dff; }

form#contactInfo {
  display: flex;
  flex-direction: column;
  justify-content: center; }
  form#contactInfo #emailContainer {
    width: 100%;
    display: flex;
    justify-content: center; }
    form#contactInfo #emailContainer input#email {
      width: 37%;
      height: 2.5em;
      border: none;
      border-radius: 3px; }
  form#contactInfo #submitContainer {
    width: 100%;
    display: flex;
    justify-content: center; }
    form#contactInfo #submitContainer button.nextStep {
      width: 37%; }
  form#contactInfo .inputContainer,
  form#contactInfo .react-international-phone-input-container {
    margin-bottom: 1em; }

#email {
  background-color: #393937;
  border: 1px solid gray;
  padding-left: 1em; }

.variantTitle {
  text-align: center; }

#buy {
  background-color: #8080ff;
  border: none;
  margin-bottom: 1em;
  color: white;
  padding: 1em;
  border-radius: 1em; }
  #buy:hover {
    background-color: #4d4dff; }

#OPTS {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-left: 0; }

main#purchase_status {
  margin-top: 5.5rem;
  padding: 2em; }

